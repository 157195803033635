<template>
	<v-sheet tabindex="0" :color="dragging ? 'var(--neutral-200)' : 'var(--neutral-100)'" class="file-input" :style="{ height: `${height}px` }" @dragenter="dragging = true" @dragleave="dragging = false" @drag="change">
		<div class="drop-label">
			<div v-if="icon === 'file'">
				<v-icon x-large icon="mdi-upload"></v-icon>				
			</div>
			<div v-if="icon === 'image'">
				<v-icon x-large icon="mdi-image"></v-icon>			
			</div>
			<span class="mt-3">Kies bestand of sleep en plaats</span>
		</div>
		<input type="file" :multiple="multiple" :accept="accept" @change="change" />
	</v-sheet>
</template>

<script>
export default {
	props: {
		height: String,
		accept: String,
		type: String,
		multiple: Boolean,
		input: Function,
	},
	data: () => ({
		dragging: false,
		icon: 'image',
	}),
	methods: {
		change(e) {
			this.dragging = false
			if (!e.target.files || e.target.files.length === 0) return

			let files = []
			for (const file of e.target.files) {
				files.push(file)
			}
			this.input(files)
		}
	}
}
</script>

<style lang="scss" scoped>
.drop-label {
	display: flex;
	flex-direction: column;
	text-align: center;
}
.file-input {
	position: relative;
	display: grid;
	place-items: center;
	width: 100%;
	min-height: 200px;
	border-radius: 5px;

	input {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}
</style>