import axiosInstance from "./api";
const cookies = require("js-cookie");
import config from "../config/index";

const setup = () => {
	axiosInstance.interceptors.request.use(
		(config) => {
			let token = cookies.get("fmw_access_token");
			if (token) {
				config.headers["Authorization"] = "bearer " + token; // for Spring Boot back-end
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	axiosInstance.interceptors.response.use(
		(res) => {
			return res;
		},
		async (err) => {
			const originalConfig = err.config;
			if (originalConfig.url !== "/v1/auth/step" && err.response) {
				// Access Token was expired
				if (err.response.status === 401 && !originalConfig._retry) {
					originalConfig._retry = true;

					try {
						const res = await axiosInstance.post("/v1/auth/token", {
							refresh_token: cookies.get("fmw_refresh_token"),
						});
						if (res.data.success) {
							cookies.set("fmw_refresh_token", res.data.data.refresh_token, {
								domain: config.cookiedomain,
							});
							cookies.set("fmw_access_token", res.data.data.access_token, {
								domain: config.cookiedomain,
							});
						}
						// TokenService.

						return axiosInstance(originalConfig);
					} catch (_error) {
						cookies.remove("fmw_refresh_token");
						cookies.remove("fmw_access_token");
						cookies.remove("user");
						let subdomain = location.hostname.split(".", 1);
						let config_main_url = config.url.main.replace("{{domain}}", subdomain[0]);
						location.replace(config_main_url);
						return Promise.reject(_error);
					}
				}
			}
			return Promise.reject(err);
		}
	);
};

export default setup;
