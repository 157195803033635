<template>


            <div class="pa-3 text-left">
                <h1>{{ $t("components.kNoPermission.perm_title") }} </h1>
                <p>{{ $t("components.kNoPermission.perm_text") }}</p>
                <k-spacing y="5"></k-spacing>
                <v-btn :color="'var(--company-primary)'" class="btn text-white" to="/">{{ $t("components.kNoPermission.perm_btn") }}</v-btn>
            </div>
  
   
</template>

<script>
export default {

};
</script>
