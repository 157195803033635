<template>
   
                <div class="h-100 d-flex align-items-center justify-content-center">
                    <va-card class="card box-shadow-card" width="913">
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-img  :src="require('@/assets/img/404.svg')"></v-img>
                            </v-col>
                            <v-col cols="12" md="8" class="my-auto">
                                <h1 class="display-4">
                                    {{ $t("components.kApp404.app_404_title") }}
                                </h1>
                                <k-spacing y="4"></k-spacing>
                                <p>  {{ $t("components.kApp404.app_404_text") }}</p>
                                <k-spacing y="4"></k-spacing>
                                <v-btn href="/contact" color="var(--company-primary)" class=" btn text-white">
                                    {{ $t("components.kApp404.app_404_btn") }}</v-btn>
                            </v-col>
                        </v-row>
                    </va-card>
                </div>
           
</template>



<style>

.box-shadow-card{
    box-shadow: 0px 6px 24px 0px rgba(78, 78, 78, 0.08)!important;
}

.h-100{
    height: 100vh;
}
.d-flex{
    display: flex;
}
.justify-content-center{
    justify-content: center;
}

.align-items-center{
    align-items: center;
}
</style>